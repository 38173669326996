import { GetServerSidePropsContext } from "next";

import { loadEntity } from "@/server/loaders";
import { isRedirect, validateEntityPath } from "@/server/slugs";
import { CompanyCardData, FeatureFlag, PersonCardData, PipelineRunStatus, userMeta } from "@/types";
import { useEffect, useState } from "react";

import EntityLayout from "@/components/entities/EntityLayout";

import NotFound from "@/components/entities/NotFound";
import { EntityPageProps, useEntityPage } from "@/hooks/useEntityPage";
import { useErrorTracker } from "@/hooks/useErrorTracker";
import { abbreviateLocation, abbreviatePosition } from "@/lib/abbreviations";
import { featureFlags } from "@/server/serverFeatureFlags";
import { loadWorkspaceData } from "@/server/workspace";
import { uiStore } from "@/stores/uiStore";
import { RelationshipDataWorkedAt } from "@/types";
import { canLoadEntity } from "@/utils/visits";
import Head from "next/head";
import { useSearchParams } from "next/navigation";
import { usePostHog } from "posthog-js/react";

export default function EntityPage(props: EntityPageProps) {
  const errorTracker = useErrorTracker();
  useEntityPage(props);
  const { entity, snapshot, status, ogDescription } = props;
  const posthog = usePostHog();
  const [missingSnapshotErrorSent, setMissingSnapshotErrorSent] = useState(false);

  useEffect(() => {
    if (status == PipelineRunStatus.IN_PROGRESS) {
      posthog?.capture("entity-in-progress", {
        entityId: entity?.id,
        type: entity?.type,
      });
    } else if (status != PipelineRunStatus.COMPLETED) {
      posthog?.capture("entity-status-" + status, {
        entityId: entity?.id,
        type: entity?.type,
      });
    }
  }, [entity, status, posthog]);

  const refreshParam = useSearchParams()?.get("refresh");

  useEffect(() => {
    uiStore.ingestUtmSource("entity-visit", { entityId: entity?.id });
    uiStore.getAndDeleteUrlParam("refresh");
  }, [entity?.id, refreshParam]);

  if (!entity) return <NotFound />;

  // this check makes sure only when the snapshot object exist do we load the layout
  if (snapshot && Object.keys(snapshot).length > 0) {
    return (
      <>
        <Head>
          {entity.name && (
            <meta property="og:title" content={`${entity.name} | Distill`} key="og-title" />
          )}
          {ogDescription && (
            <>
              <meta property="og:description" content={ogDescription} key="og-description" />
              <meta name="twitter:description" content={ogDescription} key="twitter-description" />
            </>
          )}
          {entity.imageUrl && (
            <>
              <meta property="og:image" content={entity.imageUrl} key="og-image" />
              <meta name="twitter:image" content={entity.imageUrl} key="twitter-image" />
            </>
          )}
          <meta property="og:image:height" content="100" />
          <meta property="og:image:width" content="100" />
        </Head>
        <EntityLayout {...props} />
      </>
    );
  } else {
    if (!missingSnapshotErrorSent) {
      errorTracker.sendError(new Error("Entity snapshot is empty, falling back to v2 layout"), {
        entityId: entity?.id,
        entityType: entity?.type,
        entitySlug: entity?.slug || "",
      });
      setMissingSnapshotErrorSent(true);
    }
    return null;
  }
}

export const getServerSideProps = async (context: GetServerSidePropsContext) => {
  const { user, session, redirect, entityVisitCount, cookieFragments, ...rest } =
    await loadWorkspaceData(context);

  const pathname = context.resolvedUrl;
  const queryParams = context.query;
  const slugResult = await validateEntityPath(decodeURI(pathname), context.query.id as string);

  if (!slugResult) {
    return {
      notFound: true,
    };
  }

  if (isRedirect(slugResult)) {
    const urlSearchParams = new URLSearchParams(queryParams as Record<string, string>);
    urlSearchParams.delete("id");
    return {
      redirect: {
        ...slugResult.redirect,
        destination:
          slugResult.redirect.destination + (queryParams ? "?" + urlSearchParams.toString() : ""),
      },
    };
  }

  // browsers will automatically prefetch pages sometimes - should not count towards entity visits
  const isPrefetchRequest = Boolean(
    context.req.headers["next-router-prefetch"] || context.req.headers["Next-Router-Prefetch"],
  );

  const canLoad = canLoadEntity(slugResult.id, entityVisitCount, cookieFragments, userMeta(user));
  if (!canLoad && !!user) {
    return {
      redirect: {
        destination: "/dashboard?viewLimitExceeded=true",
      },
    };
  }

  const skip = context.query.skip;
  const entityProps = await loadEntity(slugResult, session?.dbUser, {
    skipBuild: skip == "true" || isPrefetchRequest,
    entityVisitCount,
    isImpersonating: !!rest.originalUser,
  });
  let ogDescription = "";
  if (entityProps.entity.type === "person") {
    const mostRecentWorkedAt = entityProps.snapshot?.relations?.filter(
      (r) => r?.type === "worked-at",
    )[0];
    const currentPosition = abbreviatePosition(
      (mostRecentWorkedAt?.data as RelationshipDataWorkedAt)?.title,
    );
    const currentCompany = mostRecentWorkedAt?.toName;
    const currentLocation = abbreviateLocation(entityProps.snapshot?.facts?.Location?.value);
    if (currentPosition && currentCompany) {
      ogDescription = `${currentPosition} - ${currentCompany}`;
    }
    if (currentLocation) {
      ogDescription += ` • ${currentLocation}`;
    }
  } else if (entityProps.entity.type === "company") {
    const currentLocation = abbreviateLocation(
      entityProps.snapshot?.facts?.Location?.value ||
        entityProps.snapshot?.facts?.Headquarters?.value,
    );
    const currentEmployees = entityProps.snapshot?.facts?.Employees?.value;
    const founded = entityProps.snapshot?.facts?.FoundedYear?.value;
    if (currentLocation) {
      ogDescription = currentLocation;
    }
    if (currentEmployees) {
      if (ogDescription) {
        ogDescription += " • ";
      }
      ogDescription += `${currentEmployees} employees`;
    }
    if (founded) {
      if (ogDescription) {
        ogDescription += " • ";
      }
      ogDescription += `Founded ${founded}`;
    }
  }
  return clearUndefined({
    props: {
      user,
      ogDescription,
      cookieFragments,
      ...rest,
      ...entityProps,
    } as EntityPageProps,
  });
};

/**
 * Next.js doesn't allow undefined values in props. This function removes them.
 * @param obj
 * @returns
 */
function clearUndefined<T>(obj: T): T {
  return JSON.parse(JSON.stringify(obj)) as T;
}
